@font-face {
	font-family: 'Interstate Bold';
	src: url(./assets/fonts/interstatebold.otf) format('opentype');
}

@font-face {
	font-family: 'Interstate Light';
	src: url(./assets/fonts/interstatelight.otf) format('opentype');
}

*, :after, :before {
	box-sizing: unset;
}

:root {
	--main-color: white;
	--hover-color: rgb(112, 109, 109);
	--accent-color: rgb(199, 208,216);
	--side-margin: 10vw;
	--side-margin-mobile: 5vw;
	--button-color: rgb(35,116,145);
	--button-color-2: rgb(18, 60, 75);
}

html, body {
	background-color: black;
	font-family: 'Interstate Light', Helvetica, sans-serif;
	color: var(--main-color);
	padding: 0px !important;
	margin: 0px !important;
	overflow-x: hidden;
}

button {
	/* background: linear-gradient(to right, var(--button-color-2), var(--button-color)); */
	background-color: #111319;
	color: var(--main-color) !important;
	/* border: 2px solid var(--main-color); */
	border: none;
	border-radius: 8px !important;
	padding: 10px 30px !important;
	cursor: pointer;
	font-family: 'Interstate Bold', Helvetica, sans-serif !important;
	/* width: 70%; */
	font-size: 20px !important;

	transition: box-shadow 250ms ease;
}

/* button:hover {
	box-shadow: 0px 0px 6px 2px var(--hover-color);
} */

a, a:visited {
	color: inherit;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Interstate Bold', sans-serif;
}

@media screen and (max-width: 1000px) {
	button {
		font-size: 12px !important;
	}
}