.mission-logo {
	color: gray;
	fill: gray;
	height: 60%;
	width: 60%;
}

.build-bond-logo {
	color: black;
	fill: black;
	height: 150px;
	width: 150px;
}

.navbar-logo {
	color: white;
	fill: white;
	height: 40px;
	width: 40px;

	transition: fill 250ms ease;
}

a:hover, .navbar-logo:hover {
	color: var(--hover-color) !important;
	fill: var(--hover-color) !important;
}

.navbar-logo-opensea {
	height: 38px;
	width: 38px;
	color: white;
}

.navbar-logo-opensea-dark {
	height: 40px;
	width: 40px;
	margin: -15px;
	margin-right: 1px;
}

@media screen and (max-width: 1000px) {

	.navbar-logo-opensea-dark path {
		fill: #fff !important;
	}
}

@media screen and (max-width: 540px) {
	.navbar-logo {
		height: 32px;
		width: 32px;
	}

	/* .navbar-logo-opensea {
		height: 27px;
		width: 27px;
	} */

	.build-bond-logo {
		color: black;
		fill: black;
		height: 100px;
		width: 100px;
	}
}