/* body {
	overflow: hidden;
} */

.portal-button-container {
	display: flex;
	column-gap: 10px;
}

.owner-portal-button {
	background: none;
	border: 2px solid white;
}

.home {
	background-image: url(../../assets/images/bg.jpg);
	background-size: cover;
	background-position: center;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	text-align: center;
}

.top-menu-1 {
	display: flex;
	padding: 20px 5vw;
	align-items: center;
	justify-content: space-between;
}

.logo img {
	width: 5vw;
}

.banner {
	width: 100%;
	margin-top: 0vh;
	position: relative;
}

.metarelics {
	font-size: 12vw;
	padding: 10vh;
	font-family: 'Interstate Bold';
}

.spinning-cube {
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	text-align: center;
	/* margin-top: -10em; */
	height: 100%;
}

.spinning-cube img {
	height: 100%;
}

.tagline {
	margin-top: -5vh;
	text-align: center;
	font-size: 30px;
	font-family: 'Interstate Bold';
}

.relics-pass-button {
	margin-top: 5vh;
	display: flex;
	justify-content: center;
	column-gap: 30px;
}
.description {
	margin: auto;
	margin-top: 3vh;
	width: 50%;
	font-size: 20px;
	line-height: 1.5;
	font-family: 'Interstate Light';
}

.learn-more-button {
	margin-top: 5vh;
	z-index: 999;
	margin-bottom: 1vh;
}

.telephone a {
	font-family: 'Interstate Bold';
	text-decoration: none;
}

.bottom {
	/* position: absolute; */
	bottom: 0px;
	width: 100%;
}

.bottom-content {
	/* width: calc(100% - 10vh); */
	padding: 5vh;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	z-index: 1;
	margin: 0px;
}

.text-us-at p {
	margin: 0px;
	margin-top: 5px;
}

.socials {
	display: flex;
	column-gap: 50px;
}

@media screen and (max-width: 1000px) {
	.bottom-content {
		flex-direction: column;
		row-gap: 5vh;
		padding-top: 3vh;
		padding: 0px;
	}

	.socials {
		justify-content: center;
	}

	.logo img {
		width: 15vw;
	}

	.metarelics {
		font-size: 14vw;
		padding: 10vh 2vh;
		font-family: 'Interstate Bold';
	}

	.relics-pass-button {
		margin-top: 3vh;
	}

	.learn-more-button {
		margin-top: 3vh;
	}

	.banner {
		margin-top: 2vh;
	}

	.tagline {
		font-size: 15px;
		margin-top: 0px;
	}

	.description {
		width: 90%;
		font-size: 12px;
	}
}